import { ButtonLink } from '@dovera/design-system';
import { useEffect, useState } from 'react';
import IconAttachment from '../../../components/CustomIcons/IconAttachment';
import UploadForm from '../../../components/UploadForm/UploadForm';
import { Dokument } from '../../../types/models/Dokument';
import { uploadHandler } from '../../../utils/file.utils';

interface Props {
  onChange: (files: Dokument[] | null) => void;
  onUploadError: (isErr: boolean) => void;
  reset?: boolean;
  validationError?: string;
}

const UploadFiles = ({
  onChange,
  onUploadError,
  reset,
  validationError,
}: Props) => {
  const [files, setFiles] = useState<File[] | null>(null);
  useEffect(() => {
    if (files) {
      uploadHandler(files).then((files) => onChange(files));
    }
    // eslint-disable-next-line
  }, [files]);
  useEffect(() => {
    setFiles(null);
  }, [reset]);
  return (
    <UploadForm
      allowedExtensions={[
        'pdf',
        'png',
        'jpg',
        'jpeg',
        'zip',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'rtf',
      ]}
      files={
        files?.map((f) => ({
          name: f.name,
          size: f.size,
          type: f.type,
        })) || null
      }
      forbiddenExtensions={new Set(['exe'])}
      formError={validationError}
      inspectMimeTypes
      isUploading={false}
      maxFileSize={20}
      multiple
      onResetForm={(f, index, isErr) => {
        setFiles(
          files?.filter(
            (o, key) =>
              !(o.name === f.name && o.size === f.size && index === key),
          ) || null,
        );
        onUploadError(!!isErr);
      }}
      onUpload={(f, isErr) => {
        setFiles([...(files || []), ...f]);
        onUploadError(!!isErr);
      }}
      uploadError={(isErr) => onUploadError(isErr)}
      uploadFormLabelText={
        <>
          <span className="text-space-half-right">
            <b>Presuňte súbor sem alebo kliknite na</b>
            <br />
            <span
              className={
                validationError ? 'text-color-error' : 'text-color-grey-light'
              }
            >
              Prílohy nesmú prekročiť 20 MB.
            </span>
          </span>
          <ButtonLink className="no-mrg no-pad" data-type="form">
            <IconAttachment id={`reply-form--attachment`} />
            Priložiť súbor
          </ButtonLink>
        </>
      }
    />
  );
};

export default UploadFiles;
