import {
  CHARAKTER_DAVKY_CAKACKY,
  STAV_DAVKY_CAKACKY,
} from '../../types/cakacky.types';
import { DATE_TIME_FORMAT, formatDate } from '../../utils/date.utils';

export default {
  common: {
    theme: 'Čakacie listiny',
    batchPreview: 'Prehľad dávok',
    errorsPreview: 'Prehľad chýb',
    batchUpload: 'Odoslanie dávok',
  },
  batchUpload: {
    description:
      'Vyberte alebo vložte dávky, ktoré chcete odoslať. Dávky môžete zaslať jednotlivo alebo v ZIP formáte.',
    subtitle: 'Nahrajte dávky',
    notifications: {
      lastSent: {
        info: {
          btn: 'Prehľad dávok',
          msg: (date: string, order: number) =>
            `Naposledy odoslaná dávka <b>${formatDate(
              date,
            )}</b> s poradovým číslom <b>${order}</b>.`,
        },
        warning: {
          btn: 'Prevziať dávku',
          msg: (
            date: string,
            order: number,
            state?: CHARAKTER_DAVKY_CAKACKY,
          ) =>
            state === CHARAKTER_DAVKY_CAKACKY.Aktualizacna
              ? `<b>${formatDate(
                  date,
                )}</b> máte neprevzatú aktualizačnú dávku s poradovým číslom <b>${order}</b>.`
              : `<b>${formatDate(
                  date,
                )}</b> máte neprevzatú odpoveď k dávke s poradovým číslom <b>${order}</b>.`,
        },
        warningNOK: {
          msg: (date: string, order: number) =>
            `Naposledy odoslaná dávka ${formatDate(date)} s poradovým číslom ${order} nebola spracovaná. Nahrajte dávku znova s vyšším poradovým číslom alebo neskorším dátumom.`,
        },
      },
    },
    uploadLabel: `Dávku môžete  <span class="link">nahrať</span>  alebo presunúť myšou sem.`,
    validationResult: {
      title: 'Výsledok overenia dávok',
      cols: ['Dávka', 'Stav'],
      btnRemove: 'Vymazať',
      states: {
        C: 'Chybná dávka',
        P: 'Overuje sa',
        S: 'Nahratá dávka',
      },
      showErrors: 'Zobraziť chyby',
    },
    button: 'Odoslať dávky',
    updatedAt: (date: string) =>
      `Naposledy aktualizované: ${formatDate(date, DATE_TIME_FORMAT)}`,
    cancelModal: {
      btnSecondary: 'Pokračovať v odosielaní',
      btn: 'Ukončiť',
      title: 'Naozaj chcete ukončiť odosielanie dávok?',
      description: 'Dávky a nahraté prílohy nebudú uložené.',
    },
    removeModal: {
      buttons: {
        cancel: 'Ponechať dávku',
        submit: 'Vymazať',
      },
      title: 'Naozaj chcete vymazať dávku?',
      description: 'Po vymazaní bude potrebné dávku znova nahrať.',
    },
  },
  batchesProcessing: {
    title: 'Dávky sa spracovávajú',
    description:
      'Na výsledok môžete počkať, no môže to trvať niekoľko minút.<br />Keď budú spracované, nájdete ho v prehľade dávok.',
  },
  batchErrors: {
    title: (number: string) => `Chyby v dávke č. ${number}`,
    notification: {
      btn: 'Stiahnuť prehľad',
      msg: '<b>Dávku sa nepodarilo odoslať.</b> Prosím, opravte nižšie uvedené chyby v ambulantnom softvéri.',
    },
    btn: 'Späť na zoznam chybných dávok',
  },
  batchesPreview: {
    description:
      'Poskytuje prehľad Vašich odoslaných a vybavených dávok pre zaradenie pacientov na čakaciu listinu. Jednotlivé dávky si môžete stiahnuť.',
    tips: {
      title: 'Tipy na prácu s prehľadom dávok',
      description: {
        content:
          '<ul><li>Červenou farbou sú v prehľade zobrazené dávky, ktoré je potrebné stiahnuť.</li><li>Obsah dávky si zobrazíte kliknutím na jej názov.</li></ul>',
        items: [
          {
            state: STAV_DAVKY_CAKACKY.NaStiahnutie,
            content:
              'dávku je potrebné stiahnuť, aby ste si ju spárovali vo Vašom informačnom systéme',
          },
          {
            state: STAV_DAVKY_CAKACKY.Spracovanie,
            content:
              'dávka je evidovaná, počkajte, prosím, na výsledok spracovania',
          },
          {
            state: STAV_DAVKY_CAKACKY.Vybavena,
            content: 'dávka je v poriadku, nie je potrebná žiadna aktivita',
          },
        ],
      },
    },
    filter: {
      labels: {
        dateFrom: 'Dátum od',
        dateTo: 'Dátum do',
        workplace: 'Pracovisko',
        state: 'Stav',
      },
      defaults: {
        workplace: 'Všetky pracoviská',
        state: 'Všetky stavy',
      },
    },
    cols: {
      workplace: 'Pracovisko',
      name: 'Názov',
      createdAt: 'Dátum<br />vytvorenia',
      order: 'Por.<br />číslo',
      batchType: 'Typ dávky',
      state: 'Stav',
    },
    downloadBtn: 'Stiahnuť',
    states: {
      P: 'Spracováva sa',
      D: 'Na stiahnutie',
      S: 'Vybavená',
    },
    typ: {
      N: 'Nová',
      O: 'Aktualizačná',
      M: 'Odpovedná ',
    },
  },
  batchDetail: {
    title: 'Detail dávky',
    subtitleNewBatch: 'Nová dávka',
    subtitleAnswerBatch: 'Odpovedná dávka',
    subtitleUpdatedBatch: 'Aktualizačná dávka',
    labels: {
      createdAt: 'Dátum vytvorenia',
      sentAt: 'Dátum odoslania',
      order: 'Poradové číslo',
      type: 'Typ',
      workplace: 'Pracovisko',
    },
    downloadBtn: 'Stiahnuť dávku',
    cols: {
      downloadedAt: 'Dátum stiahnutia',
      type: 'Typ dávky',
      login: 'Login',
    },
    btnClose: 'Zavrieť',
  },
  sentBatches: {
    success: {
      title: 'Vaše dávky sme prijali',
      description: 'O výsledku spracovania sa dozviete v Prehľade dávok.',
      btn: 'Späť na prehľad',
    },
    error: {
      title: 'Mrzí nás to, odoslanie nebolo úspešné',
      description:
        'Neviete si s chybou poradiť? Kontaktujte nás na linke pre poskytovateľov <b>0800 150 155</b>.',
      notification: {
        btn: 'Zobraziť chyby',
        msg: (period: number) => `Dávka za obdobie <b>${period}</b>`,
      },
    },
    technicalError: {
      title: 'Mrzí nás to, nastala technická chyba',
      description: 'Prosím, skúste dávky poslať neskôr.',
      btn: 'Skúsiť znovu',
    },
  },
};
