import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  dajOvereneZuctovacieDavky,
  dajStavKontrolyZuctovacichDavok,
  odosliZuctovanieDavky,
  overStavOverenychZuctovacichDavok,
  skontrolujZuctovacieDavky,
} from '../api/zuctovanie';
import {
  DajOvereneZuctovacieDavkyResponse,
  DajStavKontrolyZuctovacichDavokResponse,
  Faktura,
  FilterBatchValues,
  STAV_DAVKY,
  SkontrolujZuctovacieDavkyResponse,
  TYP_KONTROLY_DAVKY,
  VysledokOdoslania,
  ZuctovanieState,
} from '../types/zuctovanie.types';
import { ErrorServiceResponse, LoadingState } from '../types';
import moment from 'moment';
import { DATE_FORMAT_ORACLE, DATE_TIME_FORMAT } from '../utils/date.utils';
import { translateErrorCode } from '../utils/validation.utils';
import { getSelectValue } from '../utils/dds.utils';

const getInitialPeriods = (count: number): number[] => {
  const periods: number[] = [];
  for (let i = 1; i <= count; i++) {
    periods.push(Number(moment().subtract(i, 'M').format(DATE_FORMAT_ORACLE)));
  }
  return periods;
};

export const zucInitialState: ZuctovanieState = {
  invoices: {
    detail: null,
    filter: {
      invoiceType: '',
      kodPracoviska: '',
      paperInvoice: '',
      periods: getInitialPeriods(12),
      search: '',
      state: '',
      zuc20: '',
    },
  },
  lists: {
    checked: {
      batchProcessing: true,
      data: [],
      dataState: LoadingState.none,
      error: null,
      filteredData: [],
    },
  },
  sendBatches: {
    checkedBatches: {
      data: null,
      error: null,
      isCorrectiveBatchControl: false,
      selectedIds: [],
      updatedAt: '',
    },
    dataState: LoadingState.none,
    error: null,
    groupId: '',
    isChunkedBatches: false,
    result: {
      countCalls: 0,
      data: null,
      groupId: null,
      isError: null,
    },
    uploadAttempts: 0,
  },
};

export const zuctovanieSlice = createSlice({
  name: 'zuctovanie',
  initialState: zucInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(dajOvereneZuctovacieDavky.pending.toString(), (state) => {
        state.lists.checked = {
          ...state.lists.checked,
          data: state.lists.checked.data,
          dataState: LoadingState.fetching,
          batchProcessing: true,
        };
      })
      .addCase(
        dajOvereneZuctovacieDavky.fulfilled.toString(),
        (state, action: PayloadAction<DajOvereneZuctovacieDavkyResponse>) => {
          state.lists.checked = {
            ...state.lists.checked,
            data: action.payload.udajeDavky,
            dataState: LoadingState.filled,
            batchProcessing: false,
            filteredData: action.payload.udajeDavky.map((d) => ({
              ...d,
              selected: state.lists.checked.filteredData?.some(
                (f) => f.id === d.id && f.selected,
              ),
            })),
          };
        },
      )
      .addCase(
        dajOvereneZuctovacieDavky.rejected.toString(),
        (state, action: PayloadAction<ErrorServiceResponse>) => {
          state.lists.checked = {
            ...zucInitialState.lists.checked,
            dataState: LoadingState.error,
            error: translateErrorCode(action.payload?.response?.data.kod),
            batchProcessing: false,
          };
        },
      )
      .addCase(
        overStavOverenychZuctovacichDavok.fulfilled.toString(),
        (state, action: PayloadAction<DajOvereneZuctovacieDavkyResponse>) => {
          state.lists.checked.data = action.payload.udajeDavky;
          state.lists.checked.filteredData = action.payload.udajeDavky.map(
            (d) => ({
              ...d,
              selected: state.lists.checked.filteredData?.some(
                (f) => f.id === d.id && f.selected,
              ),
            }),
          );
        },
      )
      .addCase(
        skontrolujZuctovacieDavky.pending.toString(),
        (state, action: any) => {
          if (state.sendBatches.checkedBatches.isCorrectiveBatchControl)
            state.sendBatches.checkedBatches.data =
              state.sendBatches.checkedBatches.data?.map((d) => ({
                ...d,
                stavOvereniaDavky: 'P',
              })) || [];
          state.sendBatches.dataState = LoadingState.fetching;
          if (
            action?.meta?.arg?.typKontroly?.[0] ===
              TYP_KONTROLY_DAVKY.JeOpravnaDavka &&
            state.sendBatches.checkedBatches.data?.length
          )
            state.sendBatches.checkedBatches.data =
              state.sendBatches.checkedBatches.data?.map((d) => ({
                ...d,
                stavOvereniaDavky: STAV_DAVKY.PrebiehaKontrola,
              })) || [];
          state.sendBatches.checkedBatches.isCorrectiveBatchControl = !!(
            action?.meta?.arg?.typKontroly?.[0] ===
            TYP_KONTROLY_DAVKY.JeOpravnaDavka
          );
        },
      )
      .addCase(
        skontrolujZuctovacieDavky.fulfilled.toString(),
        (state, action: PayloadAction<SkontrolujZuctovacieDavkyResponse>) => {
          state.sendBatches = {
            ...state.sendBatches,
            dataState: LoadingState.filled,
            groupId: action.payload.groupId,
            uploadAttempts: state.sendBatches.uploadAttempts + 1,
          };
        },
      )
      .addCase(
        skontrolujZuctovacieDavky.rejected.toString(),
        (state, action: PayloadAction<ErrorServiceResponse>) => {
          state.sendBatches = {
            ...state.sendBatches,
            dataState: LoadingState.error,
            error: translateErrorCode(action.payload?.response?.data.kod),
            checkedBatches: {
              ...state.sendBatches.checkedBatches,
              isCorrectiveBatchControl: false,
            },
          };
        },
      )
      .addCase(
        dajStavKontrolyZuctovacichDavok.fulfilled.toString(),
        (
          state,
          action: PayloadAction<DajStavKontrolyZuctovacichDavokResponse>,
        ) => {
          state.sendBatches.checkedBatches = {
            ...state.sendBatches.checkedBatches,
            data: action.payload.davky,
            error: null,
            updatedAt: moment().format(DATE_TIME_FORMAT),
          };
        },
      )
      .addCase(
        dajStavKontrolyZuctovacichDavok.rejected.toString(),
        (state, action: PayloadAction<ErrorServiceResponse>) => {
          state.sendBatches.checkedBatches = {
            ...zucInitialState.sendBatches.checkedBatches,
            error: translateErrorCode(action.payload?.response?.data.kod),
          };
        },
      )
      .addCase(
        odosliZuctovanieDavky.fulfilled.toString(),
        (
          state,
          action: PayloadAction<{
            idVolania: string;
            vysledokOdoslania: VysledokOdoslania;
          }>,
        ) => {
          state.sendBatches.result = {
            countCalls: 0,
            data: action.payload.vysledokOdoslania,
            groupId: action.payload.idVolania,
            isError: false,
          };
        },
      )
      .addCase(odosliZuctovanieDavky.rejected.toString(), (state) => {
        state.sendBatches.result = {
          countCalls: 0,
          data: null,
          groupId: null,
          isError: true,
        };
      });
  },
  reducers: {
    sendBatchesLoader(state, action: PayloadAction<{ type: LoadingState }>) {
      state.sendBatches.dataState = action.payload.type;
    },
    setSelectedInvoice(state, action: PayloadAction<{ invoice: Faktura }>) {
      state.invoices.detail = action.payload.invoice;
    },
    setSelectedCheckeddBatches(
      state,
      action: PayloadAction<{
        allChecked?: boolean;
        batchId?: number;
      }>,
    ) {
      state.lists.checked.batchProcessing = true;
      if (action.payload.allChecked !== undefined && state.lists.checked.data) {
        state.lists.checked.data = state.lists.checked.data.map((d) => ({
          ...d,
          selected: action.payload.allChecked,
        }));
        state.lists.checked.filteredData =
          state.lists.checked.filteredData?.map((d) => ({
            ...d,
            selected: action.payload.allChecked,
          })) || state.lists.checked.data;
      }
      if (action.payload.batchId && state.lists.checked.data) {
        state.lists.checked.data = state.lists.checked.data.map((d) => ({
          ...d,
          selected: d.id === action.payload.batchId ? !d.selected : d.selected,
        }));
        state.lists.checked.filteredData =
          state.lists.checked.filteredData?.map((d) => ({
            ...d,
            selected:
              d.id === action.payload.batchId ? !d.selected : d.selected,
          })) || state.lists.checked.data;
      }

      state.lists.checked.batchProcessing = false;
    },
    removeCheckedBatch(state, action: PayloadAction<{ id: number }>) {
      const batches =
        state.sendBatches.checkedBatches.data?.filter(
          (d) => d.id !== action.payload.id,
        ) || null;
      state.sendBatches.checkedBatches.data = batches;

      if (!batches?.length) state.sendBatches.groupId = '';
    },
    resetInvoicesFilter(state) {
      state.invoices.filter = zucInitialState.invoices.filter;
    },
    setInvoicesFilter(
      state,
      action: PayloadAction<{
        invoiceState?: string;
        invoiceType?: string;
        kodPracoviska?: string;
        paperInvoice?: string;
        periods?: number[];
        search?: string;
        zuc20?: string;
      }>,
    ) {
      const {
        invoiceState,
        invoiceType,
        kodPracoviska,
        paperInvoice,
        periods,
        search,
        zuc20,
      } = action.payload;
      state.invoices.filter = {
        state: getSelectValue(invoiceState),
        invoiceType: getSelectValue(invoiceType),
        kodPracoviska: getSelectValue(kodPracoviska),
        paperInvoice: getSelectValue(paperInvoice),
        periods: periods || [
          Number(moment().subtract(1, 'M').format(DATE_FORMAT_ORACLE)),
        ],
        search: search || '',
        zuc20: getSelectValue(zuc20),
      };
    },
    setCountCallsSendingState(state, action: PayloadAction<number>) {
      state.sendBatches.result.countCalls = action.payload;
    },
    zucFilterCheckedBatches(
      state,
      action: PayloadAction<{
        idsToDelete?: any[];
        values?: FilterBatchValues;
      }>,
    ) {
      const values = action.payload.values || null;
      const idsToDelete = action.payload.idsToDelete || null;
      let { filteredData } = state.lists.checked;
      if (values)
        filteredData =
          state.lists.checked.data?.filter(
            (d) =>
              (!values.search ||
                d.nazovSuboruDavky
                  .toLowerCase()
                  .includes(values.search.toLowerCase())) &&
              (!values.state ||
                values.state === 'all' ||
                d.stavOvereniaDavky.toString().toLowerCase() ===
                  values.state.toLowerCase()),
          ) || [];
      if (idsToDelete) filteredData = idsToDelete.filter((d) => !d.selected);
      return {
        ...state,
        lists: {
          ...state.lists,
          checked: {
            ...state.lists.checked,
            filteredData,
          },
        },
      };
    },
    zucSetSelectedBatches(state, action: PayloadAction<{ ids: number[] }>) {
      state.sendBatches.checkedBatches.selectedIds = action.payload.ids;
    },
    zucReset() {
      return zucInitialState;
    },
  },
});

export const {
  removeCheckedBatch,
  resetInvoicesFilter,
  sendBatchesLoader,
  setCountCallsSendingState,
  setInvoicesFilter,
  setSelectedCheckeddBatches,
  setSelectedInvoice,
  zucFilterCheckedBatches,
  zucReset,
  zucSetSelectedBatches,
} = zuctovanieSlice.actions;
export default zuctovanieSlice.reducer;
